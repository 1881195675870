import { List } from "linqts";

export interface ProjetVM {
  id: number;
  order: number;
  titre: string;
  sousTitre: string;
  resume: string;
  citation: string;
  banniereUrl?: string;
  cabochonUrl?: string;
  cabochonSize: number;
  carousel: CarouselVM;
  presseItems: PresseItemVM[];
  podcasts: PodcastVM[];
  podcastsAudioDesc?: string;
  podcastsVideoDesc?: string;
  produitsAssocies: ProduitAssocieVM[];
}

export interface PresseItemVM {
  citation: string;
  media: string;
}

export interface PodcastVM {
  type: PodcastType;
  url: string;
  imageUrl?: string;
  titre?: string;
}

export enum PodcastType{
  Audio,
  Video
}

export interface ProduitAssocieVM {
  titre: string;
  type: ProduitAssocieType;
  imageUrl?: string;
  ordre: number;
}

export enum ProduitAssocieType{
  MemeCollection,
  MemeEditeur
}

export interface Image{
  id: number;
  url: string;
  ordre: number;
}
export class CarouselVM {
 
  constructor(){
    this.slides = [];
  }

  public slides:CarouselSlide[];

  public populateSlides(source: Image[], nbImgPerSlide:number, selectedImage?: string){

    let nbSlide = Math.round((source.length/nbImgPerSlide)+0.49);

    for (let i = 0; i < nbSlide; i++) {
      let images = new List<Image>(source).OrderBy(x => x.ordre);
      if (i > 0)
        images = images.Where(x => x.ordre > nbImgPerSlide * i).ToList();
      let carouselImages = images.Take(nbImgPerSlide).ToArray();
      
      if(nbImgPerSlide > 1){
        let emptyImgToAdd = nbImgPerSlide - carouselImages.length;
        if (emptyImgToAdd > 0) {
          for (let x = 0; x < emptyImgToAdd; x++) {
            carouselImages.push(null);
          }
        }
      }
      
      const isSlideActive = selectedImage != null ? carouselImages[0].url == selectedImage : i == 0;
      this.slides.push({ id: i, images: carouselImages, isActive: isSlideActive });
    }
  }
}
export interface CarouselSlide{
  id: number;
  images: Image[];
  isActive: boolean;
}
