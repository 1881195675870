// ANGULAR
import { Component, Inject, OnInit } from '@angular/core';
// EXTERN
import { NgsRevealConfig } from 'ngx-scrollreveal';
// APP
import { MockApiService } from 'src/services/mock-api.service';
import { ProduitAssocieVM, ProjetVM } from 'src/models/models';
import { ProjetUtils } from 'src/models/utils';
import { BASE_PATH, InfosGeneral, PublicService } from './api/barbara_public';
import { MappingService } from 'src/services/mapping.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  public infosGeneral: InfosGeneral;
  public projets: ProjetVM[] = [];
  public widthLevel: string;
  public projetUtils: ProjetUtils = new ProjetUtils();
  public imagesRoot: string;
  public podcastsRoot: string;
  constructor(
    private ngsRevealConfig: NgsRevealConfig,
    private apiSvc: PublicService,
    private mockApiService: MockApiService,
    private mappingService: MappingService,
    @Inject(BASE_PATH) public apiBasePath: string
    ){
      this.imagesRoot = apiBasePath + '/resources/barbara/images/';
      this.podcastsRoot = apiBasePath + '/resources/barbara/podcasts/';
  }
  
  ngOnInit(){
    this.defineWidthLevel();
    this.setScrollRevealConfig();
    this.apiSvc.getGeneralInfo().subscribe(res => {
      this.infosGeneral = res;
    })
    this.apiSvc.getProjets().subscribe(res => {
      this.projets = this.mappingService.mapToProjetsVM(res);
    })
    //this.projets = this.mockApiService.getMockProjets();
  }

  defineWidthLevel(){
    const viewWidth = window.innerWidth;
    this.widthLevel = "xs";
    if(viewWidth>=576) this.widthLevel = "sm";
    if(viewWidth>=768) this.widthLevel = "md";
  }

  screenSizeChange(){
    this.defineWidthLevel();
  }

  private setScrollRevealConfig() {
    this.ngsRevealConfig.delay = 150;
    this.ngsRevealConfig.reset = true;
    this.ngsRevealConfig.duration = 350;
    this.ngsRevealConfig.easing = 'ease-in-out';
  }  
}

export interface ProduitsAssociesWrapper {
  titre: string;
  produitsAssocies: ProduitAssocieVM[];
}