import { Component } from "@angular/core";

@Component({
    selector: 'custom-footer',
    template: '<footer>&copy; 2022 Barbara Brun</footer>',
    styleUrls: ['./custom-footer.scss']
})
export class CustomFooterComponent {
    
    
}