import { Component } from "@angular/core";
//import { GAService } from "../../services/ga-service";

@Component({
    selector: 'intro',
    templateUrl: './intro.html',
    styleUrls: ['./intro.scss']
})
export class IntroComponent {

    public imgClass: string;

    constructor(
        //private gaService: GAService
        ){
        this.setImgPosition()
    }

    public setImgPosition(){
        let ratio = window.innerWidth/window.innerHeight;
        this.imgClass = ratio < (500/331) ? 'portrait' : 'landscape';
    }

    public trackSiteEnter(){
        //this.gaService.trackEvent({category: "InternalPageScroll", action: "clic", label: "Entrer (Intro)"})
    }
}