import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderMenuComponent } from 'src/components/header-menu/header-menu';
import { IntroComponent } from 'src/components/intro/intro';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AppBootstrapModule } from './bootstrap.module';
import { NgxPageScrollCoreModule, PageScrollConfig } from 'ngx-page-scroll-core';
import { InstaFeedComponent } from 'src/components/instafeed/instafeed';
import { BtnTopReturnComponent } from 'src/components/btn-top-return/btn-top-return';
import { CustomFooterComponent } from 'src/components/custom-footer/custom-footer';
import { ImageCarouselComponent } from 'src/components/image-carousel/image-carousel';
import { ImageViewerComponent } from 'src/components/image-viewer/image-viewer';
import { VimeModule } from '@vime/angular';
import { environment } from 'src/environments/environment';
import { ApiModule as BarbaraApiModule } from './api/barbara_public';

export function scrollConfig(): PageScrollConfig {
  return {
    scrollOffset: 59,
    duration: 1000,
    easingLogic: (t: number, b: number, c: number, d: number): number => {
      // easeInOutExpo easing
      if (t === 0) return b;
      if (t === d) return b + c;
      if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
      return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
  } as PageScrollConfig
}

@NgModule({
  declarations: [
    AppComponent,
    BtnTopReturnComponent,
    HeaderMenuComponent,
    IntroComponent,
    InstaFeedComponent,
    ImageViewerComponent,
    ImageCarouselComponent,
    CustomFooterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BarbaraApiModule,
    AppBootstrapModule,
    NgsRevealModule,
    NgxPageScrollCoreModule.forRoot(scrollConfig()),
    NgxPageScrollModule,
    VimeModule
  ],
  providers: [...environment.configToProvide],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
