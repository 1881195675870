// ANGULAR
import { Injectable } from '@angular/core';
import { CarouselVM, Image, PodcastType, ProduitAssocieType, ProjetVM } from 'src/models/models';
// EXTERN
// APP

@Injectable({
  providedIn: 'root'
})
export class MockApiService {

  private getMockCarousel(projetCode: string, nbImg: number){
    var images: Image[] = [];
    for (let i = 1; i <= nbImg; i++) images.push({
      id: i,
      ordre: i,
      url: 'projets/'+projetCode+'/projet-'+projetCode+'-image-'+i+'.jpg'
    });
    var carousel = new CarouselVM();
    carousel.populateSlides(images, 1);
    return carousel;
  }

  getMockProjets() {
    return [
    {
      order: 1,
      titre: 'Pom & les enfants sauvages',
      sousTitre: 'Une série écrite par Caroline Solé<br>Publiée aux éditions Albin Michel Jeunesse',
      resume: 'Le Kid, 1984 habitants. Le fleuve Twain sépare le village en deux : au sud, la zone urbaine avec sa gigantesque tour et sa population rivée aux écrans ; au nord, la zone sauvage où se sont réfugiés des enfants. Guidés par la malicieuse Pom et la douce Akhénata, ils résistent à l’ère numérique et cherchent le livre perdu des ancêtres, qui pourrait faire souffler un vent de liberté sur le village.',
      citation: 'Des histoires aux préoccupations environnementales qui peuvent se lire seul.e ou accompagné.e',
      cabochonSize: 100,
      carousel: this.getMockCarousel('pom', 5),
      presseItems: [{
        citation: 'Un joli conte futuriste pour colorer la vie.',
        media: 'France Info'
      },
      // {
      //   citation: 'Une très belle ode à la nature, à la communauté et à l’heure du conte, les dessins de Barbara Brun et la langue de Caroline Solé ; que demander de plus ?',
      //   media: 'Librairie Nouvelles & Cie'
      // },
      {
        citation: 'Cet album est une belle réussite. On retrouve toute la finesse et la fluidité de l’écriture de Caroline Solé. (…) Cette fable écologique est mise en images avec talent par Barbara Brun.',
        media: 'Agatho Croustie'
      },
      {
        citation: 'Ce petit album à l’illustration joyeuse n’est pas sans rappeler l’action entêtée d’une petite Greta avec en plus une jolie histoire d’amitié.',
        media: 'Opalivres'
      }],
      podcastsDesc: '"<strong>Lis-moi une histoire</strong>" est un podcast RTL présenté par Laurent Marsick.<br>L\'histoire est lue par Sonia Marcotte, bibliothécaire à la médiathèque Blaise-Cendrars, à Conflans-Sainte-Honorine, accompagnée par des élèves du Conservatoire de Conflans.',
      podcasts: [{
        type: PodcastType.Audio,
        url: 'podcast-pom-1.mp3',
        imageUrl: 'podcast-rtl.jpg',
        titre: 'Pom : La fête des enfants sauvages (Partie 1) - Laurent Marsick'
      },
      {
        type: PodcastType.Audio,
        url: 'podcast-pom-2.mp3',
        imageUrl: 'podcast-rtl.jpg',
        titre: 'Pom : La fête des enfants sauvages (Partie 2) - Laurent Marsick'
      }],
      produitsAssocies: [{
        titre: 'T1. Pom, la révolte des enfants sauvages',
        type: ProduitAssocieType.MemeCollection,
        imageUrl: 'couv-1'
      },
      {
        titre: 'T2. Pom, la fête des enfants sauvages',
        type: ProduitAssocieType.MemeCollection,
        imageUrl: 'couv-2'
      },
      {
        titre: 'Tout ce que j’aimerais dire aux filles, avec Sophie Carquain',
        type: ProduitAssocieType.MemeEditeur,
        imageUrl: 'couv-3'
      }]
    },
    {
      order: 2,
      titre: 'Les contes de la chouette',
      sousTitre: 'Une série écrite par Eric-Emmanuel Schmitt<br>Publiée aux éditions Hachette Jeunesse',
      resume: 'Les Contes de la Chouette constituent une série de contes écrite par Éric-Emmanuel Schmitt avec des dessins de Barbara Brun, publiés par Hachette. Le personnage principal est Minerva l’oiseau philosophe qui, dans chaque histoire, aide les héros à réfléchir et à trouver par eux-mêmes leur chemin.',
      citation: 'Les Contes de la Chouette,<br>des petits grains de sagesse distillés aux enfants',
      cabochonSize: 50,
      carousel: this.getMockCarousel('chouette', 6),
      presseItems: [{
        citation: 'Avec ses contes délicieusement illustrés, Eric-Emmanuel Schmitt renoue avec sa formation pour semer auprès des plus jeunes des graines de réflexions et de sagesse... il n\'est jamais assez tôt pour poser des questions !',
        media: 'France Info'
      }],
      podcasts: [{
        type: PodcastType.Video,
        url: 'U8i8SIUZacE'
      }],
      produitsAssocies: [{
        titre: 'Le chaton qui avait peur de tout',
        type: ProduitAssocieType.MemeCollection,
        imageUrl: 'couv-1'
      },
      {
        titre: 'L’île de la liberté',
        type: ProduitAssocieType.MemeCollection,
        imageUrl: 'couv-2'
      },
      {
        titre: 'L\'éléphant qui ne respectait rien',
        type: ProduitAssocieType.MemeCollection,
        imageUrl: 'couv-3'
      },
      // {
      //   titre: 'L\'ours qui cherchait le bonheur (à paraître)',
      //   type: ProduitAssocieType.MemeCollection,
      // }
      ] 
    },
    {
      order: 3,
      titre: 'Comme un million de papillons de noirs',
      sousTitre: 'Un album écrit par Laura Nsafou<br>Publié aux éditions Cambourakis',
      resume: 'Adé adore les éclairs au chocolat, les papillons et poser des questions.Elle a aussi de magnifiques cheveux mais ses camarades d’école s’en moquent, simplement parce qu’ils sont différents. En compagnie de sa mère et ses tantes, elle va heureusement découvrir en douceur la beauté des papillons endormis sur sa tête, jusqu’à leur envol final.',
      citation: 'Comme un million de papillons noirs porte un message universel sur la nécessité de s’accepter',
      cabochonSize: 50,
      carousel: this.getMockCarousel('papillon', 5),
      presseItems: [{
        citation: 'Imaginé pour donner confiance en soi aux enfants noirs, le livre de Laura Nsafou porte un message universel sur la nécessité de s’accepter. (...) La réussite de cet album tient aussi au travail de l’illustratrice Barbara Brun, qui signe des pages d’une grande beauté',
        media: 'Télérama'
      }],
      podcasts: [{
        type: PodcastType.Video,
        url: '3hrfrqeEofw'
      }],
      produitsAssocies: [{
        titre: 'Comme un million de papillons noirs',
        type: ProduitAssocieType.MemeEditeur,
        imageUrl: 'couv-1'
      },
      {
        titre: 'Le chemin de Jada',
        type: ProduitAssocieType.MemeEditeur,
        imageUrl: 'couv-2'
      }]
    },
    {
      order: 4,
      titre: 'Le chemin de Jada',
      sousTitre: 'Un album écrit par Laura Nsafou<br>Publié aux éditions Cambourakis',
      resume: 'Iris et Jada sont des sœurs jumelles. Elles s’entendent à merveille et partagent tout : les mêmes yeux de chat, le même nez rond, les mêmes longues nattes, le même médaillon… le seul détail qui les distingue, c’est la couleur de leur peau. Celle d’Iris est aussi claire que l’acacia tandis que celle de Jada est aussi foncée que le cacao. Dans leur village, la première est sans cesse complimentée tandis que l’autre est moquée. Un jour, Jada se lasse de ces remarques et décide de s’aventurer dans la forêt. Là-bas, elle espère rencontrer ces « enfants de la Nuit » à qui sa grand-mère ne cesse de la comparer…',
      citation: 'Que l’on soit le soleil, les étoiles ou la lune, il existe de nombreuses manières de briller',
      cabochonSize: 50,
      carousel: this.getMockCarousel('jada', 5),
      presseItems: [{
        citation: 'L’autrice Laura Nsafou et l’illustratrice Barbara Brun redonnent le \'la\' à une littérature pour enfants aussi poétique qu’engagée avec Le chemin de Jada, une superbe allégorie du colorisme',
        media: 'Terrafemina'
      },
      {
        citation: 'Le chemin de Jada livre une belle leçon de tolérance et d\'estime de soi',
        media: 'Huffingtonpost'
      },
      {
        citation: 'Le Chemin de Jada aborde avec poésie le thème du colorisme',
        media: 'Libération'
      }],
      podcasts: [{
        type: PodcastType.Video,
        url: 'wwsYtVFvZxw'
      }],
      produitsAssocies: [
      {
        titre: 'Le chemin de Jada',
        type: ProduitAssocieType.MemeEditeur,
        imageUrl: 'couv-1'
      },{
        titre: 'Comme un million de papillons noirs',
        type: ProduitAssocieType.MemeEditeur,
        imageUrl: 'couv-2'
      }]
    },
    {
      order: 5,
      titre: 'Monsieur Leblanc et l\'homme en noir',
      sousTitre: 'Un album écrit par Bernard Villiot<br>Publié aux éditions Gautier Languereau',
      resume: 'Pas un grain de sable ne vient troubler les habitudes de Monsieur Leblanc : les mercredis après-midi au parc, les déjeuners en terrasse chez Gourmand, comme il l’a toujours fait avec son épouse bien aimée. Mais un jour, un mystérieux homme en noir fait irruption dans sa vie et bouleverse son quotidien. Une fois la méfiance envolée, Monsieur Leblanc va découvrir le bonheur de l’amitié.',
      citation: 'Un livre sur le deuil, l’amitié et la quête du bonheur',
      cabochonSize: 50,
      carousel: this.getMockCarousel('leblanc', 4),
      presseItems: [{
        citation: 'Les sujets du deuil et de la solitude sont abordés d\'une manière très poétique et émouvante.',
        media: 'Babelio'
      }],
      podcasts: [],
      produitsAssocies: []
    }] as ProjetVM[];
  }
}
