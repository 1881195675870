import { Component, HostListener, ElementRef, Renderer2, OnInit } from "@angular/core";
import { List } from "linqts";
import { Projet, PublicService } from "src/app/api/barbara_public";

@Component({
    selector: 'header-menu',
    templateUrl: './header-menu.html',
    styleUrls: ['./header-menu.scss']
})
export class HeaderMenuComponent implements OnInit {
    
    public isVisible: boolean;
    public isOpen: boolean;
    public menuEntries: menuEntry[] = [];
    private projets: Projet[];
    
    constructor(
        private el: ElementRef, 
        private renderer: Renderer2,
        private apiSvc: PublicService){
    }
    
    ngOnInit(){
        this.apiSvc.getProjets().subscribe(res => {
            this.projets = res;
            this.initMenu();
        })
    }

    closeMenu() {
        this.isOpen = false;
        this.renderer.removeClass(this.el.nativeElement.querySelector('.navbar-collapse'), 'show');        
    }
    
    private initMenu(){
        this.menuEntries = [];
        this.menuEntries.push({link:"accueil", title:"Accueil", level: 0});
        this.menuEntries.push({link:"biographie", title:"Biographie", level: 0});
        this.menuEntries.push({link:"portfolio", title:"Portfolio", level: 0});
        new List<Projet>(this.projets).OrderBy(p => p.ordre).ForEach(p => {
            this.menuEntries.push({link:"projet-"+p.id, title:p.titre, level: 1});
        });
        this.menuEntries.push({link:"contact", title:"Contacts", level: 0});
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
      const scrollPosition = window.pageYOffset;
      this.isVisible = scrollPosition >= window.innerHeight/2;
    }
}

interface menuEntry{
    link: string;
    title: string;
    level: number;
}