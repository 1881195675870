import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { List } from "linqts";
import { INSTAFEED_PATH } from "src/environments/tokens";

@Component({
    selector: 'instafeed',
    templateUrl: './instafeed.html',
    styleUrls: ['./instafeed.scss']
})
export class InstaFeedComponent implements OnInit {

    constructor(
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document){
    }

    ngOnInit(): void {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = 'assets/scripts/insta-widget.js';
        this.renderer.appendChild(this.document.body, script);      
    }
}
