// ANGULAR
import { Component } from "@angular/core";
// EXTERN
import { BsModalRef } from "ngx-bootstrap/modal";
import { CarouselVM, Image } from "src/models/models";
// APP
@Component({
    selector: 'image-viewer',
    templateUrl: './image-viewer.html',
    styleUrls: ['./image-viewer.scss']
})
export class ImageViewerComponent {

    public carouselSlides: CarouselVM;
    public carouselLoaded: boolean;
    public images: Image[];
    public currentImage: string;
    
    constructor(public imageViewer: BsModalRef) {
    }

    ngOnInit(){
        this.carouselLoaded = false;
        this.carouselSlides = new CarouselVM();
        this.carouselSlides.populateSlides(this.images, 1, this.currentImage);
        this.carouselLoaded = true;
    }
}