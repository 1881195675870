import { List } from "linqts";
import { ProduitsAssociesWrapper } from "src/app/app.component";
import { PodcastType, PodcastVM, ProduitAssocieVM, ProduitAssocieType, ProjetVM } from "./models";

export class ProjetUtils{

    getProduitsAssocies(projet: ProjetVM): ProduitsAssociesWrapper[] {
        var wrapper: ProduitsAssociesWrapper[] = [];
        var allProduitsAssocies = new List<ProduitAssocieVM>(projet.produitsAssocies);
        if(allProduitsAssocies.Any(p => p.type == ProduitAssocieType.MemeCollection)){
            wrapper.push({
                titre: 'DANS LA MEME COLLECTION',
                produitsAssocies: new List<ProduitAssocieVM>(projet.produitsAssocies).Where(p => p.type == ProduitAssocieType.MemeCollection).ToArray()
            })
        }
        if(allProduitsAssocies.Any(p => p.type == ProduitAssocieType.MemeEditeur)){
            wrapper.push({
                titre: 'CHEZ LE MEME EDITEUR',
                produitsAssocies: new List<ProduitAssocieVM>(projet.produitsAssocies).Where(p => p.type == ProduitAssocieType.MemeEditeur).ToArray()
            })
        }
        return wrapper;
    }
    
    getPodcastsVideo(projet: ProjetVM): PodcastVM[] {
        return new List<PodcastVM>(projet.podcasts).Where(p => p.type == PodcastType.Video).ToArray();
    }
    getPodcastsAudio(projet: ProjetVM): PodcastVM[] {
        return new List<PodcastVM>(projet.podcasts).Where(p => p.type == PodcastType.Audio).ToArray();
    }
    hasPodcastsVideo(projet: ProjetVM): boolean{
        return new List<PodcastVM>(projet.podcasts).Any(p => p.type == PodcastType.Video);
    }
    hasPodcastsAudio(projet: ProjetVM): boolean{
        return new List<PodcastVM>(projet.podcasts).Any(p => p.type == PodcastType.Audio);
    }
    hasProduitsAssocies(projet: ProjetVM): boolean{
        return projet.produitsAssocies.length > 0
    }
    hasPresseItems(projet: ProjetVM): boolean{
        return projet.presseItems.length > 0
    }
}

