// ANGULAR
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// EXTERN
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
// APP

@NgModule({
  imports: [
    CommonModule,
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot()
  ],
  exports: [CollapseModule, ModalModule]
})
export class AppBootstrapModule {}