<nav id="mainNav" class="navbar fixed-top navbar-expand-lg navbar-light bg-light" [class.is-visible]="isVisible">
    <div class="container-fluid">
        <a class="navbar-brand center" href="#page-top" pageScroll (click)="closeMenu()">
            <img src="assets/imgs/signature.jpg" width="150px" alt="logo chapeaute">
        </a>
        <div class="navbar-menu-btn" [class.active]="isOpen" (click)="isOpen=!isOpen" data-toggle="collapse" data-target="#mainNavbarCollapse" aria-controls="mainNavbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="top"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
        </div>
        <div class="collapse navbar-collapse" id="mainNavbarCollapse">
            <hr class="bg-color-primary animated fadeIn animated.delay-0-5">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" *ngFor="let menuEntry of menuEntries">
                    <a class="nav-link text-size-up-1 text-color-dark hover-anim hover-1" 
                        [class.text-uppercase]="menuEntry.level == 0" [class.letter-spacing]="menuEntry.level == 0"
                        [class.indent]="menuEntry.level == 1" 
                        href="#{{menuEntry.link}}" pageScroll (click)="closeMenu()">
                        {{menuEntry.title}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>