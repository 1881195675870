// ANGULAR
import { Injectable } from '@angular/core';
import { List } from 'linqts';
import { CarouselImg, Podcast, PresseElement, ProduitAssocie, Projet } from 'src/app/api/barbara_public';
import { CarouselVM, Image, PodcastType, PodcastVM, PresseItemVM, ProduitAssocieType, ProduitAssocieVM, ProjetVM } from 'src/models/models';
// EXTERN
// APP

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  mapToProjetsVM(projets: Projet[]) {
    
    let projetsVm = new List<Projet>(projets).OrderBy(p => p.ordre).Select(p => {

      let images = new List<CarouselImg>(p.carouselImages).Select(ci => {
        return {
          url: ci.imgUrl,
          ordre: ci.ordre
        } as Image
      }).ToArray()
      var carousel = new CarouselVM();
      carousel.populateSlides(images, 1);

      let presseItems = new List<PresseElement>(p.presseElements).Select(pe => {
        return {
          citation:pe.citation,
          media:pe.mediaName,
          ordre: pe.ordre
        } as PresseItemVM
      }).ToArray()

      let produitsAssocies = new List<ProduitAssocie>(p.produitsAssocies).Select(pa => {
        return {
          titre: pa.titre,
          type: pa.type == 0 ? ProduitAssocieType.MemeCollection : ProduitAssocieType.MemeEditeur,
          imageUrl: pa.imgUrl,
          ordre: pa.ordre
        } as ProduitAssocieVM
      }).ToArray()

      let podcasts = new List<Podcast>(p.podcasts).Select(p => {
        return {
          type: p.type == 0 ? PodcastType.Audio : PodcastType.Video,
          url: p.url,
          ordre: p.ordre,
          imageUrl: p.imgUrl,
          titre: p.titre
        } as PodcastVM
      }).ToArray()

      let projetVm : ProjetVM = {
        id: p.id,
        titre: p.titre,
        sousTitre: p.sousTitre,
        resume: p.resume,
        citation: p.citation,
        order: p.ordre,
        cabochonUrl: p.caboImgUrl,
        banniereUrl: p.banImgUrl,
        cabochonSize: p.caboSize,
        podcastsAudioDesc: p.podcastsAudioDesc,
        podcastsVideoDesc: p.podcastsVideoDesc,
        carousel,
        presseItems,
        produitsAssocies,
        podcasts
      }

      return projetVm;
    });

    return projetsVm.ToArray();
  }
}
