// ANGULAR
import { Component, Inject, Input } from "@angular/core";
// EXTERN
import { List } from 'linqts';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BASE_PATH } from "src/app/api/barbara_public";
import { CarouselSlide, CarouselVM, Image } from "src/models/models";
// APP
import { ImageViewerComponent } from './../image-viewer/image-viewer';

@Component({
    selector: 'img-carousel',
    templateUrl: './image-carousel.html',
    styleUrls: ['./image-carousel.scss']
})
export class ImageCarouselComponent {

    @Input() title: string;
    @Input() carouselSlides: CarouselVM;
    @Input() imgClickEnable: boolean;
    @Input() isMosaique: boolean;
    @Input() showIndicators: boolean;
    @Input() showNavigators: boolean;
    @Input() interval: number;

    public imageViewer: BsModalRef;
    public emptyImgHeight: number;

    constructor(
        private modalService: BsModalService,
        @Inject(BASE_PATH) public apiBasePath: string){
    }
    
    ngOnInit(){
        setTimeout(() => this.defineEmptyImgHeight(), 1000);
    }

    openImage(imageName: string){
        if(this.imgClickEnable){
            let allImages = new List<CarouselSlide>(this.carouselSlides.slides).SelectMany(s => new List<Image>(s.images)).Where(i => i != null).ToArray();
            this.imageViewer = this.modalService.show(ImageViewerComponent, {
                class: 'modal-dialog-centered modal-lg',
                initialState: { images: allImages, currentImage: imageName }
            });
        }
    }

    getImageUrl(imageName: string): string{
        return this.apiBasePath + '/resources/barbara/images/' + imageName;
    }

    defineEmptyImgHeight(){
        this.emptyImgHeight = document.getElementsByClassName('image-item').item(0).clientHeight + 2;
    }
}