import { Component, HostListener } from "@angular/core";

@Component({
    selector: 'btn-top-return',
    template: '<a class="btn-top-return" href="#accueil" pageScroll [class.is-visible]="isVisible">TOP</a>',
    styleUrls: ['./btn-top-return.scss']
})
export class BtnTopReturnComponent {
    
    public isVisible: boolean;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
      const scrollPosition = window.pageYOffset;
      this.isVisible = scrollPosition >= 850;
    }
}