<div class="modal-body p-0">
  <ng-container *ngIf="carouselLoaded">
    <button type="button" class="close" aria-label="Close" (click)="imageViewer.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <img-carousel 
      [title]="'imgViewerCarousel'" 
      [carouselSlides]="carouselSlides"
      [showNavigators]=true>
    </img-carousel>
  </ng-container>
</div>