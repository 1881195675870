<div [id]="title" class="carousel slide" data-ride="carousel" [attr.data-interval]="interval" (window:resize)="defineEmptyImgHeight()">
    <div class="carousel-inner">
        <ng-container *ngIf="carouselSlides?.slides">
            <div *ngFor="let slide of carouselSlides.slides" class="carousel-item" [class.active]="slide.isActive">
                <div class="row justify-content-center m-0">
                    <div *ngFor="let image of slide.images" [ngClass]="{'col-4': isMosaique, 'col-sm-3': isMosaique, 'col-md-2': isMosaique}">
                        <div *ngIf="!image" class="d-block empty-image" [style.height.px]="emptyImgHeight" [style.width.px]="emptyImgHeight"></div>
                        <img *ngIf="image" class="img-fluid img-thumbnail d-block image-item" [src]="getImageUrl(image.url)" (click)="openImage(image.url)" alt="slide image">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="carouselSlides?.slides?.length >= 2">
        <ol *ngIf="showIndicators" class="carousel-indicators">
            <li *ngFor="let slide of carouselSlides.slides" [attr.data-target]="'#'+title" [attr.data-slide-to]="slide.id" [class.active]="slide.isActive"></li>
        </ol>
        <a *ngIf="showNavigators" class="carousel-control-prev" href="#{{title}}" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a *ngIf="showNavigators" class="carousel-control-next" href="#{{title}}" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </ng-container>
</div>